import Illustration, { Package, Vertical } from '../blocks';
import animationStyles from '../blocks/IllustrationAnimation.module.css';

export default function Control() {
  const size = 300;
  const width = 1200;
  const height = 800;

  return (
    <Illustration size={size} width={width} height={height}>
      <Vertical
        length={10}
        x={0}
        y={-3}
        fill="blue"
        direction="down"
        opacity={0.5}
        size={size}
      />
      <g
        className={animationStyles.moveFadeInY}
        style={{
          '--initial-offset': '-200px',
        }}
      >
        <Vertical
          length={4}
          x={0}
          y={-3.25}
          fill="purple"
          direction="down"
          opacity={1}
          size={size}
        />
      </g>
      <g
        x={0}
        y={0}
        className={animationStyles.moveFadeInY}
        style={{
          '--initial-offset': '-200px',
        }}
      >
        <Package x={0} y={0} direction="down" />
      </g>
    </Illustration>
  );
}
