import { makeAnimationOffsetPath } from '../util';
import Illustration, { Horizontal, Package } from '../blocks';

export default function Control() {
  const size = 300;
  const width = 1200;

  const packagePath = makeAnimationOffsetPath({
    size,
    width,
    commands: [
      { type: 'move', x: -4, y: 0 },
      { type: 'line', x: 4, y: 0 },
    ],
  });

  return (
    <>
      <Illustration size={size} width={width} background="light">
        <Horizontal length={5} x={-4.5} y={-0} stroke="white" opacity={0.25} />
        <Horizontal
          length={5}
          x={-0.5}
          y={-0}
          fill="salmon"
          direction="right"
        />
        <Package
          infiniteAnimation
          animationPath={packagePath}
          animationDuration={2.5}
          svgPos={{ x: 0, y: 0 }}
          x={0}
          y={0}
          direction="right"
          size={size}
        />
      </Illustration>
    </>
  );
}
