import { makeAnimationOffsetPath } from '../util';
import Illustration, { QuarterTurn, Package } from '../blocks';

export default function Control() {
  const size = 300;
  const width = 1200;
  const height = 600;

  const packageOffset = makeAnimationOffsetPath({
    size,
    width,
    height,
    commands: [
      { type: 'move', x: -1.5, y: 1.75 },
      { type: 'line', x: -1.5, y: 1.25 },
      { type: 'arc', x: 0, y: -0.25, radius: 1.5, counterClockwise: false },
      { type: 'arc', x: 1.5, y: 1.25, radius: 1.5, counterClockwise: false },
      { type: 'line', x: 1.5, y: 1.75 },
    ],
  });

  const animationDuration = 1.5;

  return (
    <>
      <Illustration size={size} width={width} height={height}>
        <QuarterTurn
          horizontal="left"
          x={0}
          y={-0.25}
          vertical="down"
          fill="purple"
          opacity={0.75}
        />
        <QuarterTurn
          horizontal="right"
          x={0}
          y={-0.25}
          vertical="down"
          fill="yellow"
          opacity={1}
        />
        <Package
          animationPath={packageOffset}
          animationDuration={animationDuration}
          infiniteAnimation
          x={0.5}
          y={-0.125}
          direction="right"
        />
      </Illustration>
    </>
  );
}
