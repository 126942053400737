import { useId } from 'react';
import { colors, transparentColors } from '../constants';
import { makeHorizontalPath, makeVerticalPath } from '../util';
import { LinearGradient } from './Gradients';

const lineFactory = (pathMaker) => {
  return function Line({
    length = 100,
    size = 100,
    x = 0,
    y = 0,
    fill = 'white',
    stroke,
    direction,
    transparentStop = '0%',
    opacity = 1,
  }) {
    const gradientId = useId();
    const color = colors[fill];
    const transparentColor = transparentColors[fill];

    return (
      <>
        {!stroke && fill && (
          <LinearGradient
            id={gradientId}
            color={color}
            transparentColor={transparentColor}
            direction={direction}
            transparentStop={transparentStop}
          />
        )}
        <path
          d={pathMaker({ length: length, size: size, x, y })}
          fill={!stroke && fill ? `url(#${gradientId})` : 'none'}
          stroke={stroke}
          strokeWidth={stroke ? size / 150 : 0}
          style={{ opacity }}
        />
      </>
    );
  };
};

export const Horizontal = lineFactory(makeHorizontalPath);
export const Vertical = lineFactory(makeVerticalPath);
