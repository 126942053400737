import Illustration, { QuarterTurn } from '../blocks';

const Meta = () => {
  return (
    <Illustration size={460} background="light">
      <QuarterTurn
        x={0.35}
        y={1}
        fill="teal"
        vertical="up"
        horizontal="right"
      />
    </Illustration>
  );
};

export default Meta;
