import Illustration, { Horizontal } from '@components/Illustration/blocks';

export default function MultiFormat() {
  return (
    <Illustration
      width={1200}
      height={1200}
      size={400}
      background="transparent"
    >
      <Horizontal
        x={-3}
        y={-2}
        length={4}
        direction="right"
        fill="white"
        opacity={0.25}
      />
      <Horizontal
        x={-2}
        y={-1}
        length={5}
        direction="right"
        fill="blue"
        opacity={0.3}
      />
      <Horizontal
        x={-2.5}
        y={0}
        length={4}
        direction="right"
        fill="teal"
        opacity={0.5}
      />
      <Horizontal
        x={0.25}
        y={0}
        length={8}
        direction="left"
        fill="white"
        opacity={0.2}
      />
      <Horizontal
        x={-2}
        y={1}
        length={4}
        direction="right"
        fill="blue"
        opacity={0.4}
      />
      <Horizontal
        x={-3}
        y={2}
        length={8}
        direction="right"
        fill="white"
        opacity={0.2}
      />
    </Illustration>
  );
}
