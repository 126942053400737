import Automate from './illustrations/Automate';
import Centralize from './illustrations/Centralize';
import Collaborate from './illustrations/Collaborate';
import Control from './illustrations/Control';
import Edge from './illustrations/Edge';
import Experience from './illustrations/Experience';
import Elasticity from './illustrations/Elasticity';
import FormatHeader from './illustrations/FormatHeader';
import Formats from './illustrations/Formats';
import Homepage from './illustrations/Homepage';
import Latency from './illustrations/Latency';
import Meta from './illustrations/Meta';
import MultiFormat from './illustrations/MultiFormat';
import Network from './illustrations/Network';
import Secure from './illustrations/Secure';
import SecureSupplyChain from './illustrations/SecureSupplyChain';
import Shipped from './illustrations/Shipped';
import Simple from './illustrations/Simple';
import Speed from './illustrations/Speed';
import Upstream from './illustrations/Upstream';
import NavigatorCallToAction from './illustrations/NavigatorCallToAction';
import NavigatorEntry from './illustrations/NavigatorEntry';
import NavigatorTeaser from './illustrations/NavigatorTeaser';

const illustrationMap = {
  Automate,
  Centralize,
  Collaborate,
  Control,
  Edge,
  Elasticity,
  Experience,
  FormatHeader,
  Formats,
  Homepage,
  Latency,
  Meta,
  MultiFormat,
  NavigatorCallToAction,
  NavigatorEntry,
  NavigatorTeaser,
  Network,
  Secure,
  SecureSupplyChain,
  Shipped,
  Simple,
  Speed,
  Upstream,
};

// import { lazy } from 'react';
// const illustrationMap = {
//   Control: lazy(() => import('./illustrations/Control')),
//   Shipped: lazy(() => import('./illustrations/Shipped')),
//   Formats: lazy(() => import('./illustrations/Formats')),
//   SecureSupplyChain: lazy(() => import('./illustrations/SecureSupplyChain')),
//   FormatHeader: lazy(() => import('./illustrations/FormatHeader')),
//   Homepage: lazy(() => import('./illustrations/Homepage')),
//   Centralize: lazy(() => import('./illustrations/Centralize')),
//   Automate: lazy(() => import('./illustrations/Automate')),
//   Experience: lazy(() => import('./illustrations/Experience')),
//   Speed: lazy(() => import('./illustrations/Speed')),
//   Upstream: lazy(() => import('./illustrations/Upstream')),
//   Edge: lazy(() => import('./illustrations/Edge')),
//   Latency: lazy(() => import('./illustrations/Latency')),
//   Elasticity: lazy(() => import('./illustrations/Elasticity')),
// };

export default illustrationMap;
