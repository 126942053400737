import Illustration, {
  QuarterTurn,
  Horizontal,
  Package,
} from '@components/Illustration/blocks';

export default function Network() {
  return (
    <Illustration
      width={1200}
      height={1200}
      size={800}
      background="transparent"
    >
      <Horizontal
        x={-2}
        y={0}
        length={2.5}
        direction="right"
        fill="blue"
        opacity={0.5}
      />
      <Horizontal
        x={0.25}
        y={0}
        length={2}
        direction="right"
        fill="white"
        opacity={0.75}
      />
      <QuarterTurn
        x={-1.75}
        y={0}
        fill="purple"
        horizontal="left"
        vertical="up"
      />
      <QuarterTurn
        x={0.25}
        y={0}
        stroke="white"
        horizontal="left"
        vertical="down"
        opacity={0.5}
      />
      <Package x={-0.75} y={0} opacity={0.5} />
    </Illustration>
  );
}
