import Illustration, { Package } from '../blocks';

const Shield = ({ size = 100, x = 0, y = 0 }) => {
  return (
    <>
      <defs>
        <radialGradient id="shieldGradient">
          <stop offset="80%" stopColor="rgba(255, 255, 255, 0)" />
          <stop offset="100%" stopColor="rgba(255, 255, 255, 1)" />
        </radialGradient>
      </defs>
      <circle
        style={{
          opacity: 0.25,
        }}
        cx={x}
        cy={y}
        r={size * 0.85}
        fill="url(#shieldGradient)"
        strokeWidth={size / 150}
      />
    </>
  );
};

const NavigatorEntry = () => {
  return (
    <Illustration width={800} size={200}>
      <Shield x={1.5} y={-0.75} color="white" size={300} />
      <Package x={1.5} y={-0.75} direction="down" opacity={1} size={300} />
      <Package x={-0.125} y={0.25} direction="right" opacity={0.5} />
      <Package x={1.25} y={1.25} direction="left" opacity={0.25} />
      <Package x={-2.125} y={-0.125} direction="up" opacity={0.25} />
      <Package x={1.125} y={1.25} direction="right" opacity={0.5} />
    </Illustration>
  );
};

export default NavigatorEntry;
