import Illustration, {
  Vertical,
  Package,
} from '@components/Illustration/blocks';

const Shield = ({ size = 100, x = 0, y = 0 }) => {
  return (
    <>
      <defs>
        <radialGradient id="shieldGradient">
          <stop offset="80%" stopColor="rgba(255, 255, 255, 0)" />
          <stop offset="100%" stopColor="rgba(255, 255, 255, 1)" />
        </radialGradient>
      </defs>
      <circle
        style={{
          opacity: 0.25,
        }}
        cx={x}
        cy={y}
        r={size * 0.85}
        fill="url(#shieldGradient)"
        strokeWidth={size / 150}
      />
    </>
  );
};

const Secure = () => {
  return (
    <Illustration width={1220} size={400}>
      <Vertical
        x={1}
        y={-3.5}
        length={4}
        fill="purple"
        direction="down"
        opacity={0.75}
      />
      <Vertical
        x={1}
        y={-0.5}
        length={4}
        fill="white"
        direction="down"
        opacity={0.33}
      />
      <Package x={1} y={-0.25} direction="down" />
      <Shield x={1} y={-0.25} size={100} color="white" />
    </Illustration>
  );
};

export default Secure;
