import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import illustrationMap from './map';

export default function Illustration({ illustration }) {
  const IllustrationComponent = illustrationMap[illustration];

  return (
    <Suspense fallback={<svg />}>
      <IllustrationComponent />
    </Suspense>
  );
}

Illustration.propTypes = {
  illustration: PropTypes.oneOf(Object.keys(illustrationMap)).isRequired,
};
