import Illustration, {
  QuarterTurn,
  Package,
} from '@components/Illustration/blocks';

export default function SimpleGradient({ fill = 'purple' }) {
  return (
    <Illustration
      width={1200}
      height={1200}
      size={2000}
      background="transparent"
    >
      <QuarterTurn x={0} y={0} fill={fill} horizontal="left" vertical="up" />
      <Package x={0.75} y={0} opacity={0.5} />
    </Illustration>
  );
}
