import { makeAnimationOffsetPath } from '../util';
import Illustration, {
  Vertical,
  Horizontal,
  QuarterTurn,
  Package,
} from '../blocks';

const Formats = () => {
  const size = 375;
  const width = 1200;

  const animationDuration = 2.5;

  const pathA = makeAnimationOffsetPath({
    size,
    width,
    commands: [
      { type: 'move', x: -1.5, y: -3 },
      { type: 'line', x: -1.5, y: -1 },
      { type: 'arc', x: 0.0, y: 0.25, radius: 1.5, counterClockwise: true },
      { type: 'arc', x: 1.5, y: 2.25, radius: 1.5 },
      { type: 'line', x: 2, y: 5 },
    ],
  });

  return (
    <Illustration size={size} width={width} background="light">
      <Vertical
        length={3}
        x={-1.5}
        y={-3.75}
        fill="teal"
        direction="down"
        opacity={0.5}
      />
      <Horizontal
        length={3}
        x={0}
        y={0.25}
        fill="purple"
        direction="right"
        opacity={0.5}
      />
      <QuarterTurn
        fill="teal"
        opacity={0.85}
        vertical="up"
        horizontal="right"
        x={0}
        y={0.25}
      />
      <QuarterTurn
        fill="blue"
        opacity={0.75}
        vertical="down"
        horizontal="left"
        x={0}
        y={0.25}
      />
      <Vertical
        length={3}
        x={1.5}
        y={1.25}
        fill="white"
        direction="down"
        opacity={0.5}
      />
      <Package
        infiniteAnimation
        animationDuration={animationDuration}
        animationPath={pathA}
        x={0.5}
        y={0.25}
        direction="right"
      />
    </Illustration>
  );
};

export default Formats;
