import animationStyles from '../blocks/IllustrationAnimation.module.css';

import Illustration, {
  Vertical,
  Horizontal,
  QuarterTurn,
  Package,
} from '../blocks';

const Halo = ({ size = 100, x = 0, y = 0, color = 'white' }) => {
  return (
    <>
      <circle
        className={animationStyles.halo}
        style={{
          '--initial-scale': 0.95,
          '--final-scale': 1,
          opacity: 0.75,
        }}
        cx={x}
        cy={y}
        r={size * 0.65}
        fill="none"
        stroke={color}
        strokeWidth={size / 150}
      />
      <circle
        className={animationStyles.halo}
        style={{
          '--initial-scale': 0.925,
          '--final-scale': 1.05,
          opacity: 0.55,
        }}
        cx={x}
        cy={y}
        r={size * 0.85}
        fill="none"
        stroke={color}
        strokeWidth={size / 150}
      />
      <circle
        className={animationStyles.halo}
        style={{
          '--initial-scale': 0.9,
          '--final-scale': 1.1,
          opacity: 0.25,
        }}
        cx={x}
        cy={y}
        r={size * 1.05}
        fill="none"
        stroke={color}
        strokeWidth={size / 150}
      />
    </>
  );
};

export default function Control() {
  const size = 455;

  return (
    <>
      <Illustration size={size} width={1200}>
        <Vertical length={3} x={0} y={-0.75} fill="blue" direction="up" />
        <QuarterTurn
          horizontal="left"
          x={-1.5}
          y={-1.75}
          vertical="down"
          fill="yellow"
        />
        <Horizontal
          length={2}
          x={-3.25}
          y={-1.75}
          direction="right"
          stroke="white"
          opacity={0.5}
        />
        <Package x={0} y={0} direction="up" />
        <Halo x={0} y={0} color="white" />
      </Illustration>
    </>
  );
}
