import Illustration, { Package } from '../blocks';

const direction = (idx) => {
  return ['up', 'down', 'left', 'right'][idx % 4];
};

const WIDTH = 800;
const HEIGHT = 600;
const SIZE = 145;

const opacity = () => {
  const baseOpacity = Math.min(1, Math.ceil(Math.random() * 8) * 0.125 + 0.25);
  return baseOpacity;
};

const shouldRenderPackage = (row, col) => {
  return (row + col) % 4 !== 2;
};

const NavigatorTeaser = () => {
  const rows = 5;
  const cols = 20;
  return (
    <Illustration width={WIDTH} height={HEIGHT} size={SIZE}>
      {Array.from({ length: rows }).map((_, row) => {
        return Array.from({ length: cols }).map((_, col) => {
          const x = col - 8.125;
          const y = row - 2;

          return shouldRenderPackage(row, col) ? (
            <g key={`${row}-${col}`}>
              <Package
                x={x}
                y={y}
                direction={direction(col + row)}
                opacity={opacity()}
                size={300}
              />
            </g>
          ) : null;
        });
      })}
    </Illustration>
  );
};

export default NavigatorTeaser;
