export const makeHorizontalPath = ({ length, size, x, y: _y }) => {
  const height = size;

  const notchSize = 0.45 * height;
  const width = Math.max(length, height);
  const y = _y - height / 2;

  const commands = [
    `M ${x - notchSize} ${y + notchSize}`,
    `L ${x} ${y}`,
    `L ${x + width} ${y}`,
    `L ${x + width + notchSize} ${y + notchSize}`,
    `L ${x + width + notchSize} ${y + height - notchSize}`,
    `L ${x + width} ${y + height}`,
    `L ${x} ${y + height}`,
    `L ${x - notchSize} ${y + height - notchSize}`,
    `L ${x - notchSize} ${y + notchSize}`,
    'z',
  ];

  return commands.join(' ');
};

export const makeVerticalPath = ({ size, length, x: _x, y }) => {
  const width = size;
  const notchSize = 0.45 * width;

  const height = Math.max(length, width);

  const x = _x - width / 2;

  const commands = [
    `M ${x + notchSize} ${y}`,
    `L ${x} ${y + notchSize}`,
    `L ${x} ${y + height - notchSize}`,
    `L ${x + notchSize} ${y + height}`,
    `L ${x + width - notchSize} ${y + height}`,
    `L ${x + width} ${y + height - notchSize}`,
    `L ${x + width} ${y + notchSize}`,
    `L ${x + width - notchSize} ${y}`,
    `L ${x + notchSize} ${y}`,
    'z',
  ];

  return commands.join(' ');
};

export const makeQuarterTurnPath = ({
  size,
  x: _x,
  y: _y,
  horizontal = 'left',
  vertical = 'down',
}) => {
  const notchSize = 0.45 * size;
  const diameter = 2 * size;

  // TODO: Add other turns of the quarter turn here
  if (horizontal === 'left' && vertical === 'down') {
    const x = _x;
    const y = _y - size / 2;

    const commands = [
      `M ${x - notchSize} ${y + notchSize}`,
      `L ${x} ${y}`,
      `A ${diameter},${diameter},0,0,1,${x + diameter},${y + diameter}`,
      `L ${x + diameter - notchSize},${y + diameter + notchSize}`,
      `L ${x + diameter - size + notchSize},${y + diameter + notchSize}`,
      `L ${x + diameter - size},${y + diameter}`,
      `A ${diameter - size}, ${diameter - size},0,0,0,${x},${y + size}`,
      `L ${x - notchSize},${y + size - notchSize}`,
      'z',
    ];

    return commands.join(' ');
  }

  if (horizontal === 'left' && vertical === 'up') {
    const x = _x;
    const y = _y + size / 2;

    const commands = [
      `M ${x - notchSize} ${y - notchSize}`,
      `L ${x} ${y}`,
      `A ${diameter},${diameter},0,0,0,${x + diameter},${y - diameter}`,
      `L ${x + diameter - notchSize},${y - diameter - notchSize}`,
      `L ${x + diameter - size + notchSize},${y - diameter - notchSize}`,
      `L ${x + diameter - size},${y - diameter}`,
      `A ${diameter - size}, ${diameter - size},0,0,1,${x},${y - size}`,
      `L ${x - notchSize},${y - size + notchSize}`,
      'z',
    ];

    return commands.join(' ');
  }

  if (horizontal === 'right' && vertical === 'down') {
    const x = _x - diameter;
    const y = _y - size / 2;

    const commands = [
      `M ${x + diameter + notchSize} ${y + notchSize}`,
      `L ${x + diameter} ${y}`,
      `A ${diameter},${diameter},0,0,0,${x},${y + diameter}`,
      `L ${x + notchSize},${y + diameter + notchSize}`,
      `L ${x + size - notchSize},${y + diameter + notchSize}`,
      `L ${x + size},${y + diameter}`,
      `A ${diameter - size}, ${diameter - size},0,0,1,${x + diameter},${
        y + size
      }`,
      `L ${x + diameter + notchSize},${y + size - notchSize}`,
      'z',
    ];

    return commands.join(' ');
  }

  if (horizontal === 'right' && vertical === 'up') {
    const x = _x - diameter;
    const y = _y + size / 2;

    const commands = [
      `M ${x + diameter + notchSize} ${y - notchSize}`,
      `L ${x + diameter} ${y}`,
      `A ${diameter},${diameter},0,0,1,${x},${y - diameter}`,
      `L ${x + notchSize},${y - diameter - notchSize}`,
      `L ${x + size - notchSize},${y - diameter - notchSize}`,
      `L ${x + size},${y - diameter}`,
      `A ${diameter - size}, ${diameter - size},0,0,0,${x + diameter},${
        y - size
      }`,
      `L ${x + diameter + notchSize},${y - size + notchSize}`,
      'z',
    ];

    return commands.join(' ');
  }
};

export const makeAnimationOffsetPath = ({
  commands,
  size,
  width = 600,
  height = 600,
}) => {
  const path = commands.map((command) => {
    switch (command.type) {
      case 'move': {
        const x = command.x * size + width / 2;
        const y = command.y * size + height / 2;
        return `M ${x} ${y}`;
      }
      case 'line': {
        const x = command.x * size + width / 2;
        const y = command.y * size + height / 2;
        return `L ${x} ${y}`;
      }
      case 'arc': {
        const x = command.x * size + width / 2;
        const y = command.y * size + height / 2;
        return `A ${command.radius * size} ${command.radius * size} 0 0 ${
          command.counterClockwise ? 0 : 1
        } ${x} ${y}`;
      }
      default:
        return '';
    }
  });

  return `path("${path.join(' ')}")`;
};
