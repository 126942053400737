import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useInView } from 'framer-motion';

const updateChild = (child, size, width, height) => {
  if (React.isValidElement(child)) {
    const isComponent = typeof child.type === 'function';

    return isComponent
      ? React.cloneElement(child, {
          size,
          x: size * child.props.x + width / 2,
          y: size * child.props.y + height / 2,
          length: child.props.length ? size * child.props.length : null,
        })
      : React.cloneElement(child, {
          children: React.Children.map(child.props.children, (grandchild) =>
            updateChild(grandchild, size, width, height),
          ),
        });
  }

  return child;
};

const Illustration = ({
  width = 600,
  height = 600,
  size = 100,
  viewportAmount = 0.5,
  children,
  background,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: viewportAmount });

  useEffect(() => {
    const svg = ref.current;

    if (inView) {
      svg.setAttribute('data-inview', true);
    } else {
      svg.removeAttribute('data-inview');
    }
  }, [inView]);

  const updatedChildren = React.Children.map(children, (child) =>
    updateChild(child, size, width, height),
  );

  return (
    <svg ref={ref} viewBox={`0 0 ${width} ${height}`} style={{ background }}>
      {updatedChildren}
    </svg>
  );
};

Illustration.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default Illustration;
