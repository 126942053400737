import Illustration, { Horizontal, QuarterTurn, Vertical } from '../blocks';

export default function Control() {
  const size = 250;
  const width = 800;
  const height = 800;

  return (
    <Illustration size={size} width={width} height={height}>
      <Horizontal x={-0.5} y={0.25} length={4} fill="blue" opacity={0.5} />
      <QuarterTurn
        x={0.25}
        y={0.25}
        vertical="down"
        stroke="white"
        opacity={0.125}
      />
      <QuarterTurn x={0.25} y={0.25} vertical="up" fill="purple" />
      <Horizontal
        x={1.5}
        y={0.25}
        length={2}
        fill="yellow"
        direction="left"
        opacity={0.125}
      />
      <Vertical
        x={1.75}
        y={-4.75}
        length={4}
        fill="teal"
        direction="down"
        opacity={0.25}
      />
    </Illustration>
  );
}
