import { makeAnimationOffsetPath } from '../util';
import Illustration, { Horizontal, QuarterTurn, Package } from '../blocks';

export default function Control() {
  const size = 200;
  const width = 1200;

  const shippedPackageOffsetPath = makeAnimationOffsetPath({
    size,
    width,
    commands: [
      { type: 'move', x: -5, y: 0 },
      { type: 'line', x: 10, y: 0 },
    ],
  });

  const droppedPackageOffsetPath = makeAnimationOffsetPath({
    size,
    width,
    commands: [
      { type: 'move', x: -5, y: 0 },
      { type: 'line', x: -0.5, y: 0 },
      { type: 'arc', x: 1.0, y: 1.5, radius: 1.5 },
      { type: 'line', x: 0, y: 4 },
    ],
  });

  const animationDuration = 2.5;

  return (
    <>
      <Illustration size={size} width={width} background="light">
        <QuarterTurn
          horizontal="left"
          x={-0.5}
          y={0}
          vertical="down"
          fill="purple"
          opacity={0.5}
        />
        <Horizontal length={10} x={-5} y={-0} fill="blue" direction="right" />
        <Package
          infiniteAnimation
          animationDuration={animationDuration}
          animationPath={droppedPackageOffsetPath}
          animationDelay={animationDuration / 2}
          x={1.5}
          y={0}
          direction="right"
          size={size}
        />
        <Package
          infiniteAnimation
          animationDuration={animationDuration}
          animationPath={shippedPackageOffsetPath}
          x={0.9}
          y={1}
          direction="right"
          size={size}
        />
      </Illustration>
    </>
  );
}
