import Illustration, { Horizontal } from '../blocks';

import styles from './Elasticity.module.css';

export default function Control() {
  const size = 200;
  const width = 1200;

  return (
    <Illustration size={size} width={width} background="light">
      <g className={styles.root}>
        <Horizontal length={5} x={-4.5} y={-0} fill="teal" direction="right" />
        <Horizontal
          length={5}
          x={-0.5}
          y={-0}
          stroke="white"
          direction="right"
        />
      </g>
    </Illustration>
  );
}
