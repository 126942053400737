// We need to keep the colors here, in order to properly transparentize them.
// If we would just use the colors from the CSS Custom Properties and generate
// a gradient to CSS `transparent` it comes out quite muddy.
export const colors = {
  blue: '#2A6FE1',
  teal: '#85DABB',
  salmon: '#ECA283',
  yellow: '#FFD482',
  purple: '#915EFF',
  white: '#FFFFFF',
};

export const transparentColors = {
  blue: 'rgba(42, 111, 225, 0)',
  teal: 'rgba(133, 218, 187, 0)',
  salmon: 'rgba(236, 162, 131, 0)',
  yellow: 'rgba(255, 212, 130, 0)',
  purple: 'rgba(145, 94, 255, 0)',
  white: 'rgba(255, 255, 255, 0)',
};
