import Illustration, { Horizontal, QuarterTurn, Package } from '../blocks';

const Centralize = () => {
  return (
    <Illustration size={225} background="light">
      <QuarterTurn
        x={1.25}
        y={0}
        fill="purple"
        vertical="down"
        horizontal="right"
        opacity={0.75}
      />
      <Horizontal
        x={-2}
        y={0}
        length={2}
        stroke="white"
        vertical="up"
        horizontal="right"
        opacity={1}
      />
      <QuarterTurn x={0} y={0} fill="teal" vertical="up" horizontal="right" />
      <QuarterTurn
        x={-0.75}
        y={0}
        fill="blue"
        vertical="down"
        horizontal="right"
        opacity={1}
      />

      <QuarterTurn
        x={1.25}
        y={0}
        vertical="up"
        horizontal="right"
        opacity={1}
      />
      <Horizontal
        x={0}
        y={0}
        length={3}
        fill="yellow"
        vertical="up"
        horizontal="right"
        opacity={1}
      />
      <Package x={0.75} y={0} />
    </Illustration>
  );
};

export default Centralize;
