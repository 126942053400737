export const LinearGradient = ({
  id,
  direction = 'right',
  color,
  transparentColor,
  colorStop = '100%',
  transparentStop = '0%',
}) => {
  const transformLut = {
    right: { x1: '0%', y1: '0%', x2: '100%', y2: '0%' },
    left: { x1: '100%', y1: '0%', x2: '0%', y2: '0%' },
    down: { x1: '0%', y1: '0%', x2: '0%', y2: '100%' },
    up: { x1: '0%', y1: '100%', x2: '0%', y2: '0%' },
  };

  return (
    <linearGradient id={id} {...transformLut[direction]}>
      <stop offset={transparentStop} stopColor={transparentColor} />
      <stop offset={colorStop} stopColor={color} />
    </linearGradient>
  );
};

export const RadialGradient = ({ id, color, transparentColor }) => {
  return (
    <radialGradient id={id} r="0%" cx="0%" cy="0%" fx="50%" fy="0%" fr="100%">
      <stop offset={'0%'} stopColor={transparentColor} />
      <stop offset={'100%'} stopColor={color} />
    </radialGradient>
  );
};
