import Illustration, { Horizontal, QuarterTurn } from '../blocks';

import animationStyles from '../blocks/IllustrationAnimation.module.css';

export default function Control() {
  const size = 455;
  const width = 1200;
  const height = 1200;

  return (
    <>
      <Illustration size={size} width={width} height={height}>
        <g
          className={animationStyles.fade}
          style={{
            '--initial-opacity': 1,
            '--final-opacity': 0.25,
            '--animation-duration': '2s',
          }}
        >
          <QuarterTurn
            x={-0.25}
            y={0}
            horizontal="right"
            vertical="up"
            stroke="white"
            opacity={0.25}
            size={size}
          />
          <QuarterTurn
            x={1.5}
            y={0}
            horizontal="right"
            vertical="up"
            stroke="white"
            opacity={0.25}
            size={size}
          />
          <Horizontal
            length={2}
            x={-3}
            y={0}
            direction="left"
            stroke="white"
            opacity={0.25}
            size={size}
          />
          <Horizontal
            length={4}
            x={0}
            y={0}
            direction="left"
            stroke="white"
            opacity={0.25}
            size={size}
          />
          <QuarterTurn
            x={0.25}
            y={0}
            horizontal="right"
            vertical="down"
            stroke="white"
            opacity={0.25}
            size={size}
          />

          <QuarterTurn
            x={1.25}
            y={0}
            horizontal="right"
            vertical="down"
            stroke="white"
            opacity={0.25}
            size={size}
          />
        </g>
        <g
          className={animationStyles.fade}
          style={{
            '--initial-opacity': 0,
            '--final-opacity': 1,
            '--animation-duration': '2s',
          }}
        >
          <Horizontal
            length={2}
            x={0}
            y={0}
            direction="left"
            fill="teal"
            opacity={0.5}
            size={size}
          />
          <Horizontal
            fill="blue"
            length={4}
            x={-2}
            y={0}
            direction="right"
            opacity={0.88}
            size={size}
          />
        </g>
      </Illustration>
    </>
  );
}
