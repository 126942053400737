import animationStyles from '../blocks/IllustrationAnimation.module.css';

import Illustration, { Package } from '../blocks';

const Shield = ({ size = 100, x = 0, y = 0 }) => {
  return (
    <>
      <defs>
        <radialGradient id="shieldGradient">
          <stop offset="80%" stopColor="rgba(255, 255, 255, 0)" />
          <stop offset="100%" stopColor="rgba(255, 255, 255, 1)" />
        </radialGradient>
      </defs>
      <circle
        className={animationStyles.halo}
        style={{
          '--initial-scale': 0.95,
          '--final-scale': 1.05,
          '--animation-duration': '4s',
          opacity: 0.125,
        }}
        cx={x}
        cy={y}
        r={size * 0.85}
        fill="url(#shieldGradient)"
        strokeWidth={size / 150}
      />
    </>
  );
};

const NavigatorCallToAction = () => {
  return (
    <Illustration widht={800} size={400}>
      <Package x={0} y={0} direction="down" opacity={0.35} />
      <Shield x={0} y={0} size={400} color="white" />
    </Illustration>
  );
};

export default NavigatorCallToAction;
