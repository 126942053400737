import Illustration, { Horizontal } from '@components/Illustration/blocks';

import animationStyles from '../blocks/IllustrationAnimation.module.css';

export default function Control() {
  const size = 850;
  const width = 1200;
  const height = 800;

  return (
    <Illustration size={size} width={width} height={height}>
      {Array.from({ length: 10 }).map((_, i) => (
        <g
          key={i}
          className={animationStyles.moveFadeInX}
          x={0}
          y={0}
          style={{
            '--animation-duration': `${0.75 - i * 0.05}s`,
            '--initial-offset': `${i * -20}px`,
          }}
        >
          <Horizontal
            length={2}
            x={-3 + i * 0.5}
            y={0}
            fill="purple"
            direction="right"
            opacity={0.35}
            size={size}
          />
        </g>
      ))}
    </Illustration>
  );
}
