import Illustration, { Horizontal } from '../blocks';

const FormatHeader = () => {
  return (
    <Illustration width={800} height={800} size={1200}>
      <Horizontal x={-2} y={0} length={1.75} fill="purple" opacity={0.5} />
      <Horizontal
        x={0.25}
        y={0}
        length={4}
        direction="left"
        fill="blue"
        opacity={0.25}
      />
    </Illustration>
  );
};

export default FormatHeader;
