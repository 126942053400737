import { makeAnimationOffsetPath } from '../util';
import Illustration, { Horizontal, QuarterTurn, Package } from '../blocks';

const Centralize = () => {
  const illustrationSize = 300;
  const illustrationWidth = 800;
  const illustrationHeight = 800;
  const animationDuration = 4;

  const packageAnimationPath = makeAnimationOffsetPath({
    size: illustrationSize,
    width: illustrationWidth,
    height: illustrationHeight,
    commands: [
      { type: 'move', x: -5, y: 0 },
      { type: 'line', x: 5, y: 0 },
    ],
  });

  return (
    <Illustration
      size={illustrationSize}
      width={illustrationWidth}
      height={illustrationHeight}
    >
      <Horizontal x={-2.5} y={0} length={3.5} direction="left" fill="yellow" />
      <QuarterTurn x={0} y={0} fill="purple" vertical="up" horizontal="left" />
      <QuarterTurn
        x={0}
        y={0}
        fill="salmon"
        vertical="down"
        horizontal="right"
        opacity={0.5}
      />
      <Horizontal
        x={0}
        y={0}
        length={3.5}
        direction="left"
        stroke="white"
        opacity={0.5}
      />

      <Package
        infiniteAnimation
        animationPath={packageAnimationPath}
        animationDuration={animationDuration}
        animationDelay={(animationDuration / 3) * 2}
        svgPos={{ x: 0, y: 0 }}
        x={0.5}
        y={0}
        opacity={0.75}
        size={illustrationSize}
      />

      <Package
        infiniteAnimation
        animationPath={packageAnimationPath}
        animationDuration={animationDuration}
        animationDelay={(animationDuration / 3) * 2}
        svgPos={{ x: 0, y: 0 }}
        x={0.5}
        y={0}
        opacity={0.75}
        size={illustrationSize}
      />
    </Illustration>
  );
};

export default Centralize;
