import Illustration, { Horizontal } from '@components/Illustration/blocks';

export default function Collaborate() {
  return (
    <Illustration width={800} height={800} size={1200} background="transparent">
      <Horizontal x={-2.15} y={0} length={1.75} fill="blue" opacity={0.5} />
      <Horizontal
        x={0.4}
        y={0}
        length={4}
        direction="left"
        fill="purple"
        opacity={0.5}
      />
    </Illustration>
  );
}
